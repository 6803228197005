
const initialState = {
  isOnTop: 'hero'
};

const SET_INDEX = 'SET_INDEX';

export const toggleTop = isOnTop => ({
  type: SET_INDEX,
  isOnTop
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INDEX:
      return {
        ...state, isOnTop: action.isOnTop
      };
    default:
      return state;

  }
}


